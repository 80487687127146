import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    event.preventDefault()
    
    // Find the all-stats div
    const statsDiv = document.getElementById("all-stats")
    const isHidden = statsDiv.classList.contains("hidden")
    const buttonLabel = document.getElementById("stats-toggle-label")
    
    // Toggle visibility of stats div
    statsDiv.classList.toggle("hidden")
    
    // Update link text based on visibility
    buttonLabel.textContent = isHidden ? "Hide" : "View All"
  }
} 