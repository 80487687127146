import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "toggleButton",
    "toggleMarker",
    "enabledInput",
    "calendarToggleButton",
    "calendarToggleMarker",
    "calendarEnabledInput",
    "profileForm",
    "calendarForm" 
  ]

  toggleEnabled() {
    this.toggleButtonTarget.classList.toggle("bg-blue-600")
    this.toggleButtonTarget.classList.toggle("bg-gray-200")
    this.toggleMarkerTarget.classList.toggle("translate-x-5")
    this.toggleMarkerTarget.classList.toggle("translate-x-0")
    this.profileFormTarget.classList.toggle("block")
    this.profileFormTarget.classList.toggle("hidden")

    if(this.enabledInputTarget.value === "true") {
      this.enabledInputTarget.value = "false"
    } else {
      this.enabledInputTarget.value = "true"
    }
  }

  toggleCalendarEnabled() {
    this.calendarToggleButtonTarget.classList.toggle("bg-blue-600")
    this.calendarToggleButtonTarget.classList.toggle("bg-gray-200")
    this.calendarToggleMarkerTarget.classList.toggle("translate-x-5")
    this.calendarToggleMarkerTarget.classList.toggle("translate-x-0")

    if(this.calendarEnabledInputTarget.value === "true") {
      this.calendarEnabledInputTarget.value = "false"
    } else {
      this.calendarEnabledInputTarget.value = "true"
    }
  }
}
